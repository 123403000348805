import fetch from '../../../apis/request'

export default class Apis {
  // 根据组织code获得组织机构树状结构
  static getNzTree(id) {
    return fetch.get(`/pc/department/getNzTree?departmentCode=${id}`)
  }
  // 添加组织机构
  static addZz(form) {
    return fetch.post('/pc/department/add', form)
  }
  // 编辑组织机构
  static updateZz(form) {
    return fetch.post('/pc/department/update', form)
  }
  // 删除组织机构
  static delZz(data) {
    return fetch.post(`/pc/department/del?id=${data}`)
  }
  // 添加组织用户
  static addZzyh(form) {
    return fetch.post('/pc/organization/add', form)
  }
  // 移除组织用户
  static removeZzyh(data) {
    return fetch.post(`/pc/organization/remove?id=${data}`)
  }
  // 获得组织机构选择用户所需的用户列表
  static getUserList(formData) {
    return fetch.get('/pc/user/getUser', {
      params: formData
    })
  }
  // 根据组织机构code和角色id获得所需要的的用户
  static findByDepartmentId(code, id) {
    return fetch.get(`/pc/organization/findAll?departmentCode=${code}&roleId=${id}`)
  }
  // 根据类型获得角色
  static findByRoleType(type='') {
    return fetch.get(`/pc/role/findByRoleType?roleType=${type}`)
  }


  // 角色树状结构
  static findTree(type='') {
    return fetch.get(`/pc/role/findTree?roleType=${type}`)
  }
  // 根据角色id获得角色权限设置
  static findByRoleId(data) {
    return fetch.get(`/pc/role/findByRoleId?id=${data}`)
  }
  // 更新角色权限
  static updatePermission(form) {
    return fetch.post('/pc/role/updatePermission', form)
  }

  // 获得安全检查项
  static findSafetyList(formData) {
    return fetch.get('/pc/safety-check_item/page', {
      params: formData
    })
  }


  // 获得用户列表
  static findUserList(formData) {
    return fetch.get('/pc/user/list', {
      params: formData
    })
  }
  // 添加用户
  static addUser(form) {
    return fetch.post('/pc/user/add', form)
  }
  // 编辑用户
  static updateUser(form) {
    return fetch.post('/pc/user/update', form)
  }
  // 删除用户
  static delUser(data) {
    return fetch.post(`/pc/user/del?id=${data}`)
  }
  // 重置密码
  static resetPwd(data) {
    return fetch.post(`/pc/user/resetPwd?id=${data}`)
  }

  // 获得用户信息
  static getUserInfo() {
    return fetch.get('/pc/user/getLoginUserInfo')
  }
}
